export const basicColor = {
  /** 主题色 */
  primary: '#9800F5',
  /** 渐变主题色 */
  primaryLinearGradient: ['#8700DA', '#7000FF'],
  proxyLinearGradient: ['rgba(131,0,0,0.00)', '#830000'],
  proxybgColor: '#C92619',
  proxyResetLink: '#FFEFEF',
  proxylightLinear: ['#E51251', '#FF8A00'],
  loginLinearGradient: ['#F9F0FF', '#FFF'],
  rechargeButtonLinearGradient: ['#e51251', '#ff8a00'],
  invitationRecordLinearGradient: ['#FBCBCB', 'rgba(255, 255, 255, 0.00)'],
  red: 'red',
  white: '#fff',
  dark: '#000',
  transparent: '#0000',
  invitationRuleColors: {
    tableHeader: '#FF6868',
    tableEven: '#FFD5D5',
    tableOdd: '#FFE6E8',
  },
  darkPrimary: '#1D9429',
  lightPrimary: '#F6E9FF',
};

export const fontColor = {
  main: '#000',
  second: '#31373D',
  accent: '#5F6975',
  secAccent: '#9FA5AC',
  grey: '#D8E0EA',
  colorMyBets: '#374637',
};

export const backgroundColor = {
  /** 蓝 */
  main: '#9800F5',
  /** 橙 */
  second: '#F15802',
  /** 红 */
  accent: '#E20000',
  /** 绿 */
  secAccent: '#009919',
  grey: '#D8E0EA',
  /** 浅灰色,用于图片加载前的颜色 */
  palegrey: '#E8EBEE',
  lightGrey: '#EFF1F7',
  //中奖状态 背景渐变色
  wonLinearGradient: ['rgba(241, 88.04, 2, 0.08)', 'rgba(241, 88.04, 2, 0)'],
  //未中奖状态 背景渐变色
  noWinLinearGradient: ['#F0F0F0', 'rgba(240, 240, 240, 0)'],
  //未开奖状态 背景渐变色
  toDrawnLinearGradient: ['rgba(23, 118, 255, 0.08)', 'rgba(23, 118, 255, 0)'],
  blue: '#1776FF',
  orange: '#F15802',
  blueGrey: '#B0AEA0',
  palegreyNext: '#F6F7FA',
  // color
  timeLinearGradient: ['#D3FFDA', '#ffffff'],
  colorDashed: '#7AA17E',
  remainingLinearGradient: ['#CBF3CF', '#E8FFED'],
  ruleBtn: '#EDFFEF',
  colorQuickLinearGradient: ['#C4CFCA', '#CEDCD6'],
  colorBall: '#BCCAC4',
  rateItem: '#D1F2D5',
  colorBets: '#CAD6D1',
  diceResultBg: '#9DC6A8',
  diceResultBgLight: '#BBE4C6',
  colorBetsListBg: '#8E9D97',
  colorBetsListBgLight: '#95A39D',
  colorBetsDotBg: '#677764',
  betsBgLight: '#EFFAF0',
  betsBgDark: '#00830D',

  //
  sattaDigitShadow: 'rgba(151.83, 0, 244.89, 0.20)',

  quickDigitChatHeaderBg: '#AFBFC0',
};

export const fontSize = {
  xs: 10,
  s: 12,
  m: 14,
  l: 16,
  xl: 20,
};

export const paddingSize = {
  zorro: 0,
  xxs: 4,
  xs: 6,
  s: 8,
  m: 10,
  l: 12,
  xl: 14,
  xxl: 16,
};

export const borderRadiusSize = {
  xxs: 2,
  xs: 4,
  s: 6,
  m: 8,
  l: 10,
  xl: 12,
};

export const iconSize = {
  xxs: 10,
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
  xl: 28,
  xxl: 32,
};

export const imageSize = {
  xs: 36,
  s: 48,
  m: 64,
  l: 72,
};

export const invitationBaseColors = {
  signGiftBgColor: basicColor.primary,
  registSuccess: basicColor.primary,
};
